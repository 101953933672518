import createStyle from 'utils/createStyle';

const styles = createStyle({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: (th) => `1px solid ${th.palette.surface.paper}`,
    borderRadius: '0.5rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  imageContainer: {
    width: '100%',
    aspectRatio: '219 / 125',
    position: 'relative',
    overflow: 'hidden',
  },
  name: {
    width: '100%',
  },
  contents: {
    width: '100%',
    p: 2,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  seprator: {
    width: '100%',
    borderTop: (th) => `1px solid ${th.palette.surface.paper}`,
    my: '0.875rem',
  },
});

export default styles;
