import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { karnamehServices } from 'layout/Header/data';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import theme from 'theme';

import KImage from 'components/kImage';
import emblaStyles from './embla.module.css';
import styles from './styles';

export type HomeHeroProps = {
  banners: BannerItem[];
};

const DesktopHero = ({ banners }: HomeHeroProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    direction: 'rtl',
    align: 'center',
    containScroll: 'keepSnaps',
    loop: true,
  });
  const [currentIndex, setCurrentIndex] = useState(0);

  // const scrollToPrev = useCallback(
  //   () => emblaApi && emblaApi.scrollPrev(),
  //   [emblaApi],
  // );

  const scrollToNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  );

  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi],
  );

  const onSelect = useCallback((api: EmblaCarouselType) => {
    setCurrentIndex(api.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', onSelect);
      setInterval(() => {
        scrollToNext();
      }, 5000);
    }
  }, [emblaApi, onSelect, scrollToNext]);

  const { push } = useRouter();

  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.carouselContainer}>
        <Stack sx={styles.slider}>
          {/*<IconButton*/}
          {/*  onClick={scrollToPrev}*/}
          {/*  sx={{*/}
          {/*    border: '1px solid white',*/}
          {/*    borderRadius: '0.5rem',*/}
          {/*    position: 'absolute',*/}
          {/*    zIndex: 2,*/}
          {/*    left: '9.75rem',*/}
          {/*    [theme.breakpoints.up('lg')]: {*/}
          {/*      left: 'calc(((100vw - 90.75rem)/2) + 9.75rem)',*/}
          {/*    },*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <ArrowForward*/}
          {/*    sx={{*/}
          {/*      color: 'white',*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</IconButton>*/}
          <Stack sx={styles.emblaContainer} className={emblaStyles.embla}>
            <Box className={emblaStyles.embla__viewport} ref={emblaRef}>
              <Box className={emblaStyles.embla__container}>
                {banners.map((item, i) => (
                  <Box
                    onClick={() => {
                      if (item.redirect_url) {
                        push(item.redirect_url);
                      }
                    }}
                    sx={{
                      cursor: item.redirect_url ? 'pointer' : 'default',
                    }}
                    className={emblaStyles.embla__slide}
                    key={`desktop-slider-${item.id}`}
                  >
                    <Stack
                      className={emblaStyles.embla__slide__img}
                      sx={{
                        width: '100%',
                        height: '9.25rem',
                        position: 'relative',
                        [theme.breakpoints.up('lg')]: {
                          width: '90.75rem',
                          maxWidth: '90.75rem',
                          marginX: 'auto',
                        },
                      }}
                    >
                      <KImage
                        priority={i === 0}
                        loading={i === 0 ? 'eager' : 'lazy'}
                        alt={`desktop-slider-${item.id}`}
                        src={item.desktop_url}
                        layout="fill"
                        quality={50}
                      />
                    </Stack>
                  </Box>
                ))}
              </Box>
            </Box>
          </Stack>
          {/*<IconButton*/}
          {/*  onClick={scrollToNext}*/}
          {/*  sx={{*/}
          {/*    border: '1px solid white',*/}
          {/*    borderRadius: '0.5rem',*/}
          {/*    position: 'absolute',*/}
          {/*    zIndex: 2,*/}
          {/*    right: '9.75rem',*/}
          {/*    [theme.breakpoints.up('lg')]: {*/}
          {/*      right: 'calc(((100vw - 90.75rem)/2) + 9.75rem)',*/}
          {/*    },*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <ArrowBack sx={{ color: 'white' }} />*/}
          {/*</IconButton>*/}
        </Stack>
      </Stack>
      <Stack
        width="100%"
        mt="-7.5rem"
        px="10%"
        overflow="hidden"
        gap="1rem"
        zIndex={2}
      >
        <Stack
          flexDirection="row"
          width="100%"
          justifyContent="center"
          alignItems="center"
          gap="0.3rem"
          marginTop="0.5rem"
        >
          {banners.length > 1 &&
            banners.map((item, index) => {
              return (
                <Box
                  key={index}
                  borderRadius="99rem"
                  onClick={() => {
                    if (index === currentIndex) return;
                    scrollTo(index);
                  }}
                  width={index === currentIndex ? '1rem' : '0.4rem'}
                  height="0.4rem"
                  sx={{
                    backgroundColor: () =>
                      currentIndex === index
                        ? theme.palette.iconDefault.main
                        : 'white',
                  }}
                />
              );
            })}
        </Stack>
        <Stack
          bgcolor="white"
          border="1px solid #EBEBEB"
          borderRadius="1.5rem"
          py="2rem"
          px="5rem"
        >
          <Grid
            container
            sx={styles.gridContainer}
            spacing={0}
            rowSpacing={1.5}
          >
            {karnamehServices
              .sort((a, b) => {
                return a.id - b.id;
              })
              .map((item, index) => {
                const Icon = item.icon;
                if (index < 8 || showMore)
                  return (
                    <Grid
                      xs={3}
                      item
                      sx={styles.servicesGrid}
                      key={`karnameh-service-${item.id}`}
                      mb="1rem"
                    >
                      <NextLink href={item.link || ''} passHref>
                        <Link sx={styles.serviceLink}>
                          <Stack
                            sx={{
                              width: '3.5rem',
                              height: '3.5rem',
                              backgroundColor: '#EBF8F1',
                              borderRadius: '0.5rem',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {Icon && <Icon type="component" size="36" />}
                          </Stack>
                          <Typography sx={styles.serviceTitle}>
                            {item.title}
                          </Typography>
                        </Link>
                      </NextLink>
                    </Grid>
                  );
              })}
          </Grid>
          {karnamehServices.length > 8 && (
            <Stack
              sx={styles.showMoreContainer}
              onClick={() => setShowMore((prevState) => !prevState)}
            >
              <Typography sx={styles.showMoreText}>
                {showMore ? 'کمتر' : 'بیشتر'}
              </Typography>
              {showMore ? (
                <KeyboardArrowUpIcon sx={styles.showMoreIcon} />
              ) : (
                <KeyboardArrowDownIcon sx={styles.showMoreIcon} />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
export default DesktopHero;
