import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import logo from 'assets/logos/min-logo.svg';
import Badge from 'components/Badges';
import KImage from 'components/kImage';
import NextLink from 'next/link';
import { FC } from 'react';
import { getCarCompleteName } from 'utils/cars';
import styles from './styles';

interface PropTypes extends CarPostItem {}

const AdsItem: FC<PropTypes> = (props) => {
  const {
    brand_name_fa: brandNameFa,
    model_name_fa: modelNameFa,
    type_name_fa: typeNameFa,
    usage,
    year,
    price,
    concierge_sale_token: conciergeSaleToken,
    is_leasing_available: isLeasingAvailable,
    should_attach_inspection_report: shouldAttachInspectionReport,
    car_images: carImages,
  } = props;

  const mainImage =
    carImages.find((item) => item.is_cover)?.url || carImages[0]?.url || null;

  const { nameFa } = getCarCompleteName({
    brandFa: brandNameFa,
    modelFa: modelNameFa || '',
    typeFa: typeNameFa || '',
  });

  const theme = useTheme();

  return (
    <NextLink href={`/used-cars/${conciergeSaleToken}`} passHref>
      <Stack component={Link} sx={styles.container}>
        <Stack sx={styles.imageContainer}>
          <KImage
            src={mainImage || '/assets/images/plp/image-not-found.jpg'}
            layout="fill"
            objectFit="cover"
            alt="AdsItem"
          />
        </Stack>

        <Stack sx={{ position: 'absolute', top: '0.75rem', left: '0.75rem' }}>
          {shouldAttachInspectionReport && (
            <Badge
              imgUrl={logo}
              label="کارشناسی شده"
              backgroundColor={theme.palette.success.main}
            />
          )}

          {isLeasingAvailable ? (
            <Badge
              isIcon={true}
              icon={
                <AccountBalanceWalletIcon
                  sx={{
                    color: (th) => th.palette.common.white,
                    fontSize: 15,
                  }}
                />
              }
              label="تسهیلات فوری"
              backgroundColor={theme.palette.secondary.main}
            />
          ) : null}
        </Stack>

        <Stack sx={styles.contents}>
          <Typography
            fontSize="0.875rem"
            fontWeight={600}
            color={(th) => th.palette.common.black}
            sx={styles.name}
            noWrap
          >
            {nameFa}
          </Typography>
          <Typography
            mt={1}
            fontSize="0.625rem"
            color={(th) => th.palette.iconDefault.main}
          >
            {year} - {Number(usage).toLocaleString()} کیلومتر
          </Typography>

          <Box sx={styles.seprator} />

          <Typography
            fontSize="0.75rem"
            color={(th) => th.palette.common.black}
          >
            {Number(price).toLocaleString()} ت
          </Typography>
        </Stack>
      </Stack>
    </NextLink>
  );
};

export default AdsItem;
