import { Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { FC, ReactNode } from 'react';

interface Props {
  backgroundColor: string;
  label: string;
  imgUrl?: string;
  isIcon?: boolean;
  icon?: ReactNode;
  border?: string;
  color?: string;
  fontWeight?: number;
  ml?: number | string;
  mb?: number | string;
}

const Badge: FC<Props> = ({
  backgroundColor,
  label,
  imgUrl,
  isIcon = false,
  icon,
  border,
  color,
  fontWeight,
  ml,
  mb,
}) => {
  return (
    <Stack
      sx={{
        height: '1.5rem',
        py: '0.25rem',
        px: '0.6rem',
        flexDirection: 'row',
        borderRadius: 99999,
        backgroundColor,
        mb: mb ? mb : 1,
        ml: ml ? ml : 0,
        border: border ? border : 'none',
      }}
    >
      <Stack
        sx={{
          width: '0.56rem',
          height: '0.87rem',
          minWidth: '0.56rem',
          minHeight: '0.87rem',
          position: 'relative',
        }}
      >
        {isIcon ? (
          icon
        ) : (
          <Image
            src={imgUrl as string}
            layout="fill"
            objectFit="contain"
            alt={label}
          />
        )}
      </Stack>
      <Typography
        color={color ? color : (th) => th.palette.common.white}
        fontSize="0.75rem"
        ml="0.6rem"
        fontWeight={fontWeight ? fontWeight : 500}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default Badge;
