import { Stack, useMediaQuery } from '@mui/material';
import MetaTags from 'components/MetaTags';
import Articles from 'containers/home/articles';
import HomeBanner from 'containers/home/banner';
import DesktopHero from 'containers/home/hero/desktop';
import MobileHero from 'containers/home/hero/mobile';
import LatestAds from 'containers/home/latest-ads';
import { HomeMetaData } from 'containers/home/metadata';
import PopularCarPrice from 'containers/home/popular-car-price';
import styles from 'containers/home/styles';
import UsedCarPrice from 'containers/home/used-car-price';
import VideoListSection from 'containers/karnama/components/video-list-section';
import useTrace from 'hooks/useTrace';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import theme from 'theme';

const LastOrderServiceSticky = dynamic(import('components/Notification'), {
  ssr: false,
});
interface PropsTypes extends HomeSSRData {}

const Home: NextPage<PropsTypes> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {});

  useTrace('home', 'home-page-load');

  return (
    <>
      <MetaTags
        title={HomeMetaData.title}
        description={HomeMetaData.description}
        canonical={HomeMetaData.canonical}
        viewport="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
      >
        <meta
          data-react-helmet="true"
          name="google-site-verification"
          content="JqVBR8HZ_c1g9llI8vi4xihSfXc4zZcFqH_rqWDkxSo"
        />
        <script
          id="home-json-ld"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(HomeMetaData.jsonLd),
          }}
        />
      </MetaTags>
      <Stack
        sx={{
          maxWidth: '100%',
          width: '100%',
          backgroundColor: 'white',
        }}
      >
        <Stack sx={styles.container}>
          <LastOrderServiceSticky />
          <MobileHero banners={props.banners.heroBanners} />
          <DesktopHero banners={props.banners.heroBanners} />
          {props.carAds && props.carAds.length > 0 && (
            <LatestAds ads={props.carAds} />
          )}
          {isMobile && props.banners.myCar && (
            <HomeBanner banner={props.banners.myCar} />
          )}
          {props.prices && props.prices.length > 0 && (
            <PopularCarPrice prices={props.prices} />
          )}
          {props.videos && props.videos.length > 0 && (
            <VideoListSection
              headingTitle="آخرین ویدئوها"
              headingAction="مشاهده همه"
              actionLink="/karnama"
              videoList={props.videos}
            />
          )}
          {isMobile && props.banners.favList && (
            <HomeBanner banner={props.banners.favList} />
          )}
          <UsedCarPrice />
          <Articles articles={props.blogs} />
        </Stack>
      </Stack>
    </>
  );
};

export default Home;
